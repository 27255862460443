<template>
  <div class="w-full bg-blue-500 border-t-4 border-gray-900 text-white py-6">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="grid grid-cols-2 gap-4">
        <div class="col-span-2 lg:col-span-1">
          <a class="flex items-center mb-4 " href="https://github.com/Cointopay/BanckryptoNode" target="_blank">
            <img alt="Github" src="@/assets/img/GitHub-Mark-32px.png"/>
            <p class="mx-3">Download Banckrypto Full Node</p>
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"></path>
            </svg>
          </a>

          <a class="flex items-center" href="https://github.com/Cointopay/Banckrypto" target="_blank">
            <img alt="Github" src="@/assets/img/GitHub-Mark-32px.png"/>
            <p class="mx-3">Download Banckrypto Site</p>
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"></path>
            </svg>
          </a>
        </div>

        <div class="col-span-2 lg:col-span-1 flex items-center justify-end">
          <a class="icon mr-2" href="https://t.me/cointopay" target="_blank">
            <svg fill="currentColor" height="24px"
                 style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"
                 version="1.1" width="24px"
                 xml:space="preserve"
                 xmlns="http://www.w3.org/2000/svg"><path id="telegram-1" d="M18.384,22.779c0.322,0.228 0.737,0.285 1.107,0.145c0.37,-0.141 0.642,-0.457 0.724,-0.84c0.869,-4.084 2.977,-14.421 3.768,-18.136c0.06,-0.28 -0.04,-0.571 -0.26,-0.758c-0.22,-0.187 -0.525,-0.241 -0.797,-0.14c-4.193,1.552 -17.106,6.397 -22.384,8.35c-0.335,0.124 -0.553,0.446 -0.542,0.799c0.012,0.354 0.25,0.661 0.593,0.764c2.367,0.708 5.474,1.693 5.474,1.693c0,0 1.452,4.385 2.209,6.615c0.095,0.28 0.314,0.5 0.603,0.576c0.288,0.075 0.596,-0.004 0.811,-0.207c1.216,-1.148 3.096,-2.923 3.096,-2.923c0,0 3.572,2.619 5.598,4.062Zm-11.01,-8.677l1.679,5.538l0.373,-3.507c0,0 6.487,-5.851 10.185,-9.186c0.108,-0.098 0.123,-0.262 0.033,-0.377c-0.089,-0.115 -0.253,-0.142 -0.376,-0.064c-4.286,2.737 -11.894,7.596 -11.894,7.596Z"/></svg>
          </a>
          <a class="icon" href="https://discord.gg/VbXchwu" target="_blank">
            <svg class="w-6 h-6" clip-rule="evenodd" fill-rule="evenodd" fill="currentColor"
                 image-rendering="optimizeQuality" shape-rendering="geometricPrecision"
                 text-rendering="geometricPrecision" viewBox="0 0 294334 333333" xmlns="http://www.w3.org/2000/svg">
              <g id="Layer_x0020_1">
                <g id="_489768256">
                  <path class="fil0"
                        d="M178012 139855c-9581 0-17146 8237-17146 18491s7733 18491 17146 18491c9581 0 17146-8237 17146-18491s-7733-18491-17146-18491zm-61354 0c-9581 0-17146 8237-17146 18491s7733 18491 17146 18491c9581 0 17146-8237 17146-18491 168-10254-7564-18491-17146-18491z"/>
                  <path class="fil0"
                        d="M259706 0H34459C15465 0 0 15465 0 34459v225247c0 18995 15465 34459 34459 34459h190620l-8909-30761 21516 19835 20339 18659 36309 31434V34458C294166 15464 278701-1 259706-1zm-64885 217683s-6052-7228-11094-13448c22020-6219 30425-19835 30425-19835-6892 4539-13448 7733-19331 9918-8405 3530-16474 5715-24374 7228-16137 3026-30929 2185-43537-168-9581-1849-17818-4370-24710-7228-3866-1513-8068-3362-12271-5715-504-336-1009-504-1513-840-336-168-504-337-672-337-3026-1681-4707-2857-4707-2857s8068 13280 29417 19667c-5043 6388-11263 13784-11263 13784-37149-1177-51269-25383-51269-25383 0-53622 24205-97159 24205-97159 24206-17986 47067-17482 47067-17482l1681 2017c-30257 8573-44041 21853-44041 21853s3698-2017 9918-4707c17987-7901 32274-9918 38158-10590 1009-168 1849-336 2857-336 10254-1345 21853-1681 33955-337 15969 1849 33115 6556 50596 15969 0 0-13280-12607-41855-21180l2354-2689s23029-504 47066 17482c0 0 24206 43537 24206 97159 0-168-14120 24038-51269 25215z"/>
                </g>
              </g>
            </svg>
          </a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.icon {
  width: 48px;
  height: 48px;
  border: 2px solid #FFFFFF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}

.icon:hover {
  opacity: .5;
}
</style>
